import firebase from "./../../config/FirebasConfig";
import "firebase/firestore";

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;
  const userRef = firebase.firestore().doc(`users/${userAuth.uid}`);
  const snapShot = await userRef.get();
  if (!snapShot.exists) {
    const { first_name, last_name, email } = userAuth;
    const creation_date = Date();
    try {
      userRef.set({
        first_name,
        last_name,
        email,
        creation_date,
        ageshow: false,
        availabilityshow: false,
        businesslocationshow: false,
        businessnameshow: false,
        farmaddressshow: false,
        farmnameshow: false,
        farmorgshow: false,
        farmtypeshow: false,
        firstnameshow: false,
        jobhistoryshow: false,
        locationshow: false,
        mobileshow: false,
        personaldetailshow: false,
        sershow: false,
        showBusiness: false,
        showUsernameWarning: false,
        ...additionalData,
      });
    } catch (error) {
      console.log("Error creating user:", error.message);
    }
  }

  return userRef;
};

// MARK: - READING
export const fetchUser = (uid) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data());
          resolve(doc.data());
        }
      })
      .catch((err) => {
        console.log("Fetch User Error", err.message);
        reject(err);
      });
  });
};

// MARK: - WRITING
export const saveUserToFirestore = (data) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("users")
      .doc(data.uid)
      .set(data, { merge: true })
      .then(() => {
        console.log("Successfully saved user to firestore!");
        resolve();
      })
      .catch((err) => {
        console.log("Save User to Firestore Error", err.message);
        reject(err);
      });
  });
};

export const updateUserDetails = (uid, data) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("courses")
      .doc(uid)
      .set(data, { merge: false })
      .then(() => {
        console.log("Successfully updated user!");
        resolve();
      })
      .catch((err) => {
        console.log("Update user Error", err.message);
        reject(err);
      });
  });
};

export const deleteAccountFromFirestore = (uid) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .delete()
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.log("Error deleting user from Firestore:", error.message);
        reject(error);
      });
  });
};

export const createListing = (data) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("listings")
      .add(data, { merge: true })
      .then((docRef) => {
        console.log("Successfully created listing!");
        resolve(docRef.id);
      })
      .catch((err) => {
        console.log("Create listing Error", err.message);
        reject(err);
      });
  });
};

export const fetchListings = (userID) => {
  return new Promise((resolve, reject) => {
    let query = firebase.firestore().collection("listings");
    query = query.where("status", "!=", "closed");
    query.onSnapshot(
      (querySnapshot) => {
        if (querySnapshot.empty) {
          return;
        }
        let listings = [];
        querySnapshot.forEach((snap) => {
          listings.push({
            id: snap.id,
            ...snap.data(),
          });
        });
        resolve(listings);
      },
      (error) => {
        console.log(error);
        console.log("Fetch listings Error", error.message);
        reject(error);
      }
    );
  });
};

export const getListing = (listid) => {
  return new Promise((resolve, reject) => {
    let query = firebase.firestore().collection("listings").doc(listid);
    let listing = [];
    query
      .get()
      .then((res) => {
        if (res.data()) {
          let query1 = firebase
            .firestore()
            .collection("users")
            .doc(res.data().poster_id);
          query1.get().then((res1) => {
            listing.push({
              id: listid,
              firstname: res1.data().first_name,
              lastname: res1.data().last_name,
              username: res1.data().username,
              profileimg: res1.data().profileimg,
              profilelocation: res1.data().location ? res1.data().location : "",
              profilelocationlat: res1.data().locationlat
                ? res1.data().locationlat
                : "",
              profilelocationlng: res1.data().locationlng
                ? res1.data().locationlng
                : "",
              ...res.data(),
            });
            resolve(listing);
          });
        } else {
          resolve(listing);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateListing = (listing_id, data) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("listings")
      .doc(listing_id)
      .set(data, { merge: true })
      .then(() => {
        console.log("Successfully updated listing!");
        resolve();
      })
      .catch((err) => {
        console.log("Update listing Error", err.message);
        reject(err);
      });
  });
};

export const deleteListing = (id) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("listings")
      .doc(id)
      .delete()
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.log("Error deleting listing from Firestore:", error.message);
        reject(error);
      });
  });
};

// --- Analytics ---

export const updateUserInfo = (data) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("users")
      .doc(data.uid)
      .set(data, { merge: true })
      .then(() => {
        console.log("Successfully updated user info");
        resolve();
      })
      .catch((err) => {
        console.log("Update User Error", err.message);
        reject(err);
      });
  });
};
