import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/images/Logo.png";
import { BsPlus, BsSearch } from "react-icons/bs";
import defaultAvatar from "../assets/images/default-avatar.png";
import { logoutUser } from "./../utils/firebase/auth";

// const profileimg = `Profile`;

const Logo = styled.img`
  width: 143px;
  height: 30px;
`;
const Profile = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 30px;
`;
const HeaderDiv = styled.div`
  padding: 20px 30px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #e2e2ea;
  @media (max-width: 1200px) {
    position: fixed;
    width: 100%;
    z-index: 5;
  }
`;
const PostList = styled.span`
  background: #01a3a4;
  border-radius: 10px;
  font-family: PoppinsLight;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #fafafb;
  padding: 8px 15px;
  &:hover {
    cursor: pointer;
  }
`;
const Search = styled.input`
  padding: 7px 7px 7px 35px;
  background: #fafafb;
  border-radius: 10px;
  font-family: RobotoLight;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  border: none;
`;

function Header(props) {
  const history = useHistory();
  const [pathURL, setPath] = useState(window.location.pathname);
  const { currentUser } = props;

  useEffect(() => {
    return history.listen((location) => {
      setPath(location.pathname);
      console.log(`You changed the page to: ${location.pathname}`);
    });
  }, [history]);

  useEffect(() => {
    setPath(window.location.pathname);
    console.log(`You changed the page to: ${window.location.pathname}`);
  }, [setPath]);

  return (
    <HeaderDiv>
      <Logo src={logo} alt="logo" />
      <div
        className={`float-right ${
          pathURL !== "/signin" && pathURL !== "/signup" && pathURL !== "/"
            ? "d-none"
            : ""
        }`}
      >
        <Link
          className={`signbtn ${pathURL === "/signup" ? "active" : ""}`}
          to="/signup"
        >
          Join
        </Link>
        <Link
          className={`signbtn ${
            pathURL === "/signin" || pathURL === "/" ? "active" : ""
          }`}
          to="/signin"
        >
          Log in
        </Link>
      </div>
      <div
        className={`float-right ${
          pathURL !== "/account" && pathURL !== "/generalsetting"
            ? "d-none"
            : ""
        }`}
      >
        <span
          className="logoutbtn cursor-pointer"
          onClick={() => {
            logoutUser();
          }}
        >{`Log Out >`}</span>
      </div>
      <div
        className={`float-right ${
          pathURL !== "/profile" &&
          pathURL.split("/")[1] !== "viewprofile" &&
          pathURL.split("/")[1] !== "listings" &&
          pathURL !== "/business-profile" &&
          pathURL !== "/mydocument" &&
          pathURL !== "/mylisting" &&
          pathURL !== "/myjob" &&
          pathURL !== "/resource" &&
          pathURL !== "/message"
            ? "d-none"
            : ""
        }`}
      >
        <div className="d-none d-md-inline">
          <BsSearch
            size={16}
            style={{
              position: "relative",
              left: "28px",
              color: "#01A3A4",
              zIndex: "2",
            }}
          />
          <Search type="text" placeholder="Search" className="mr-1" />
          <PostList className="mr-3" onClick={props.handleModal}>
            <BsPlus size={20} className="mr-1" />
            Post a Listing
          </PostList>
          <Profile
            src={
              currentUser != null && currentUser.profileimg !== undefined
                ? currentUser.profileimg
                : defaultAvatar
            }
            alt="profile"
          />
        </div>
      </div>
      <div className={`${pathURL.includes("account") ? "d-none" : ""}`}>
        <div>
          <Profile
            src={
              currentUser != null && currentUser.profileimg !== undefined
                ? currentUser.profileimg
                : defaultAvatar
            }
            alt="profile"
            className="d-md-none float-right"
          />
          <div>
            <div className="d-md-none pt-3">
              <PostList className="btn" onClick={props.handleModal}>
                <BsPlus size={20} />
                Post a Listing
              </PostList>
              <div className="mt-3" style={{ height: "35px" }}>
                <Search
                  type="text"
                  placeholder="Search"
                  style={{ width: "100%" }}
                />
                <BsSearch
                  size={16}
                  style={{
                    marginLeft: "10px",
                    marginTop: "-62px",
                    color: "#01A3A4",
                    zIndex: "2",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderDiv>
  );
}

export default Header;
