import firebase from "./../../config/FirebasConfig";
import "firebase/firestore";
import { saveUserToFirestore, deleteAccountFromFirestore } from "./firestore";
import Swal from "sweetalert2";

export const auth = firebase.auth();
// Google Auth
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => firebase.auth().signInWithPopup(provider);

export const registerUser = (data, stopLoading) => {
  return new Promise((resolve, reject) => {
    auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((result) => {
        const user = result.user;
        console.log("Successfully registered user!", user);
        alert("Your account was created! Please check your inbox.");
        stopLoading();
        if (user) {
          data.uid = user.uid;
          data.creation_date = Date();
          data.jobs_completed = 0;
          data.listings = 0;
          data.welly_dones = 0;
          delete data.password;
          saveUserToFirestore({
            ...data,
            ageshow: false,
            availabilityshow: false,
            businesslocationshow: false,
            businessnameshow: false,
            farmaddressshow: false,
            farmnameshow: false,
            farmorgshow: false,
            farmtypeshow: false,
            firstnameshow: false,
            jobhistoryshow: false,
            locationshow: false,
            mobileshow: false,
            personaldetailshow: false,
            sershow: false,
            showBusiness: false,
            showUsernameWarning: false,
          })
            .then(() => {
              auth.currentUser
                .sendEmailVerification()
                .then((res) => {
                  console.log("Sent verification code, with res:", res);
                  resolve();
                })
                .catch((err) => {
                  console.log("err sending verification:", err);
                  reject(err);
                });
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((err) => {
        console.log("Registration Error", err.message);
        alert("Registration Error: " + err.message);
        stopLoading();
        reject(err);
      });
  });
};

export const loginUser = (email, password, stopLoading) => {
  return new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        if (response) {
          if (!response.user.emailVerified) {
            alert("No email verified. Please check email inbox");
            stopLoading();
          }
          console.log("Successfully logged in user!");
          resolve();
        }
      })
      .catch((error) => {
        console.log("Login Error:", error.message);
        alert("Login Error: " + error.message);
        stopLoading();
        reject(error);
      });
  });
};

export const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        alert("Reset email sent! Check your inbox");
        resolve();
      })
      .catch(function (error) {
        // An error happened.
        alert("Reset Password Error: " + error);
        reject(error);
      });
  });
};

export const deleteAccount = () => {
  return new Promise((resolve, reject) => {
    var user = firebase.auth().currentUser;
    const uid = user.uid;
    Swal.fire({
      title: "Are you sure you want to deactivate your account?",
      showCancelButton: true,
      confirmButtonText: "Deactivate",
      customClass: {
        confirmButton: "btn alert-confirm-btn",
        cancelButton: "btn alert-cancle-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAccountFromFirestore(uid)
          .then(() => {
            resolve();
            logoutUser();
            user
              .delete()
              .then(function () {
                // User deleted.
                console.log("User deleted");
              })
              .catch(function (error) {
                console.log("Error deleting user:", error.message);
                reject(error);
              });
          })
          .catch((error) => {
            console.log("Error deleting user from Firestore:", error.message);
            reject(error);
          });
      }
    });

    // user
    //   .delete()
    //   .then(function () {
    //     // User deleted.
    //     console.log("User deleted");
    //     deleteAccountFromFirestore(uid)
    //       .then(() => {
    //         resolve();
    //         logoutUser();
    //       })
    //       .catch(error => {
    //         console.log("Error deleting user from Firestore:", error.message);
    //         reject(error);
    //       });
    //   })
    //   .catch(function (error) {
    //     console.log("Error deleting user:", error.message);
    //     reject(error);
    //   });
  });
};

export const logoutUser = () => {
  auth
    .signOut()
    .then(function () {
      console.log("Successfully logged out!");
    })
    .catch(function (error) {
      console.log(error);
    });
};
