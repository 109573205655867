// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyB_QD79si_2_ujQFevjUsmt9v2tUQnOrqM",
   authDomain: "learntoburn-fd9b6.firebaseapp.com",
   projectId: "learntoburn-fd9b6",
   storageBucket: "learntoburn-fd9b6.appspot.com",
   messagingSenderId: "765650797221",
   appId: "1:765650797221:web:c839e8e46c4d5619f1434c",
   measurementId: "G-FVT9ELPXRK",
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
export const db = firebase.database();
export const channel = db.ref("channel");
