import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  BsFunnel,
  BsChevronDown,
  BsChatDots,
  BsJustifyLeft,
  BsHouseDoor,
  BsPaperclip,
  BsFillPlusCircleFill,
  BsCalendar,
  BsJustify,
  BsX,
} from "react-icons/bs";
import { connect } from "react-redux";
import defaultimg from "../assets/images/Noreview.png";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";

import {
  createListing,
  getListing,
  updateListing,
} from "./../utils/firebase/firestore";
import firebase from "firebase";

const Button = styled.button`
  padding: 9px 35px;
  font-family: PoppinsLight;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  background: #01a3a4;
  border-radius: 10px;
  border: none;
`;
const Modaltitle = styled.h2`
  font-family: PoppinsRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #171725;
  border-bottom: 1px solid #f1f1f5;
  width: 100%;
  text-align: left;
`;
const Subtitle = styled.div`
  margin-top: 15px;
  width: 100%;
  text-align: left;
  h3 {
    display: inline-block;
    margin-left: 22px;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #171725;
  }
`;
const Subcontent = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 30px;
  margin-top: 10px;
  font-family: RobotoLight;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
  .select-dropdown,
  .select-dropdown * {
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
  }
  .select-dropdown {
    position: relative;
    background: #fafafb;
    border-radius: 10px;
  }
  .select-dropdown select {
    font-family: RobotoLight;
    width: 100%;
    padding: 8px 10px 8px 30px;
    color: #000;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .select-dropdown select:active,
  .select-dropdown select:focus {
    outline: none;
    box-shadow: none;
  }
  .select-dropdown span {
    position: absolute;
    top: 7px;
    right: 14px;
  }
  select:invalid {
    color: gray !important;
  }
  input,
  textarea {
    width: 100%;
    padding: 8px 10px 8px 30px;
    color: #000;
    border: none;
    background: #fafafb;
    border-radius: 10px;
  }
`;
const Btn = styled.button`
  font-family: PoppinsLight;
  font-weight: 600;
  color: #92929d;
  background: #fff;
  border: 1px solid #92929d;
  border-radius: 20px;
  font-size: 12px;
  margin: 0px 10px 15px 0;
  width: 120px;
  height: 38px;
  &.active {
    border: 1px solid #44444f;
    color: #44444f;
  }
`;
const Uploadimg = styled.div`
  margin-left: 10px;
  width: 82%;
  position: relative;
`;
const Uploaded = styled.div`
  width: 40%;
  position: relative;
  img {
    width: 100%;
  }
`;
const alertStyle = {
  fontFamily: "PoppinsLight",
  fontWeight: "600",
  fontSize: "13px",
};
class Listmodal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poster_id: this.props.currentUser ? this.props.currentUser.uid : "",
      listing_type: "",
      listing_title: "",
      business_name: "",
      business_info: "",
      listing_name: "",
      listing_relocate: false,
      listing_sector: "",
      listing_category: "",
      listing_mutiplerole: false,
      listing_experienceneeded: false,
      listing_accomodation: false,
      listing_volunteer: false,
      listing_url: "",
      listing_frequency: "seasonal",
      listing_description: "",
      listing_files: [],
      listing_availability: [],
      listing_online: false,
      listing_kind: "",
      date_created: "",
      listing_startdate: "",
      listing_payprice: 0,
      listing_paytype: "hour",
      listing_location: "",
      listing_locationlat: "",
      listing_locationlng: "",
      status: "active",
      showListingTypeWarning: false,
      showListingTitleWarning: false,
      showListingDesWarning: false,
      showListingLocWarning: false,
      address: "",
    };
  }
  handleChildValueChanged = (obj) => {
    this.setState(obj);
  };
  selectListingType = (e) => {
    // if (this.props.currentUser.membership === "basic") {
    //   if (
    //     e.currentTarget.value === "Earn2Burn Wanted" ||
    //     e.currentTarget.value === "Earn2Burn Available" ||
    //     e.currentTarget.value === "Training Services Available" ||
    //     e.currentTarget.value === "Events"
    //   ) {
    //     alert("You are not permitted");
    //     return;
    //   }
    // }
    // if (this.props.currentUser.membership === "standard") {
    //   if (e.currentTarget.value === "Earn2Burn Available") {
    //     alert("You are not permitted");
    //     return;
    //   }
    // }
    this.handleChildValueChanged({
      listing_type: e.currentTarget.value,
    });
  };
  selectbooleanBtn = (event, val) => {
    var elements = document.getElementsByClassName("booleanBtn");
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove("active");
    }
    event.target.classList.add("active");
    this.handleChildValueChanged({ listing_mutiplerole: val });
  };
  selectbooleanRelocateBtn = (event, val) => {
    var elements = document.getElementsByClassName("booleanRelocateBtn");
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove("active");
    }
    event.target.classList.add("active");
    this.handleChildValueChanged({ listing_relocate: val });
  };
  selectbooleanOnlineBtn = (event, val) => {
    var elements = document.getElementsByClassName("booleanOnlineBtn");
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove("active");
    }
    event.target.classList.add("active");
    this.handleChildValueChanged({ listing_online: val });
  };
  selectbooleanKindBtn = (event, val) => {
    var elements = document.getElementsByClassName("booleanKindBtn");
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove("active");
    }
    event.target.classList.add("active");
    this.handleChildValueChanged({ listing_kind: val });
  };
  selectbooleanVolunteerBtn = (event, val) => {
    var elements = document.getElementsByClassName("booleanVolunteerBtn");
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove("active");
    }
    event.target.classList.add("active");
    this.handleChildValueChanged({ listing_volunteer: val });
  };
  selectExperienceBtn = (event, val) => {
    var elements = document.getElementsByClassName("experienceBtn");
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove("active");
    }
    event.target.classList.add("active");
    this.handleChildValueChanged({ listing_experienceneeded: val });
  };
  selectAccomodationBtn = (event, val) => {
    var elements = document.getElementsByClassName("accomodationBtn");
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove("active");
    }
    event.target.classList.add("active");
    this.handleChildValueChanged({ listing_accomodation: val });
  };
  selectFrequencyBtn = (event, val) => {
    var elements = document.getElementsByClassName("frequencyBtn");
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove("active");
    }
    event.target.classList.add("active");
    this.handleChildValueChanged({ listing_frequency: val });
  };
  selectAvailabilityBtn = (event, val) => {
    var arr = this.state.listing_availability;
    if (event.target.classList.contains("active")) {
      event.target.classList.remove("active");
      arr = arr.filter((item) => item !== val);
    } else {
      event.target.classList.add("active");
      if (arr.indexOf(val) === -1) {
        arr.push(val);
      }
    }
    this.handleChildValueChanged({ listing_availability: arr });
  };
  uploadfile = (event) => {
    const file = event.target.files[0];
    const vm = this;
    const storageRef = firebase.storage().ref(`listing/${file.name}`).put(file);

    storageRef.on(
      `state_changed`,
      (snapshot) => {},
      (error) => {
        console.log(error.message);
      },
      () => {
        firebase
          .storage()
          .ref("listing")
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            let dataVal = this.state.listing_files;
            dataVal.push(url);
            vm.handleChildValueChanged({
              listing_files: dataVal,
            });
          });
      }
    );
  };
  remove = (index) => {
    let dataVal = this.state.listing_files;
    dataVal.splice(index, 1);
    this.handleChildValueChanged({
      listing_files: dataVal,
    });
  };
  handleSave = () => {
    var data = this.state;
    var flag = false;
    if (data.listing_type === "") {
      this.setState({ showListingTypeWarning: true });
      flag = true;
    } else {
      this.setState({ showListingTypeWarning: false });
    }
    if (data.listing_title === "") {
      this.setState({ showListingTitleWarning: true });
      flag = true;
    } else {
      this.setState({ showListingTitleWarning: false });
    }
    if (data.listing_description === "") {
      this.setState({ showListingDesWarning: true });
      flag = true;
    } else {
      this.setState({ showListingDesWarning: false });
    }

    if (flag === true) return;
    this.props.handleModal();
    delete data.showListingTypeWarning;
    delete data.showListingTitleWarning;
    delete data.showListingDesWarning;
    delete data.showListingLocWarning;
    delete data.address;

    if (this.props.listid === 0) {
      data.date_created =
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).substr(-2) +
        "-" +
        ("0" + new Date().getDate()).substr(-2) +
        " " +
        ("0" + new Date().getHours()).substr(-2) +
        ":" +
        ("0" + new Date().getMinutes()).substr(-2);
      createListing(data).then(() => {
        console.log("saved listing");
        document.location = "./../mylisting";
      });
    } else {
      updateListing(this.props.listid, data).then(() => {
        console.log("updated listing");
        document.location = "./../mylisting";
      });
    }
  };
  componentDidMount() {
    let elements2 = document.getElementsByClassName("imgurl");
    for (let i = 0; i < elements2.length; i++) {
      elements2.item(i).onerror = function () {
        this.src = defaultimg; // place your error.png image instead
      };
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.listid !== prevProps.listid) {
      getListing(this.props.listid).then((listing) => {
        if (listing.length === 0) {
          return;
        }
        this.setState(
          {
            listing_type: listing[0].listing_type,
            listing_title: listing[0].listing_title,
            business_name: listing[0].business_name,
            business_info: listing[0].business_info,
            listing_description: listing[0].listing_description,
            listing_name: listing[0].firstname + " " + listing[0].lastname,
            listing_relocate: listing[0].listing_relocate,
            listing_sector: listing[0].listing_sector,
            listing_category: listing[0].listing_category,
            listing_mutiplerole: listing[0].listing_mutiplerole,
            listing_experienceneeded: listing[0].listing_experienceneeded,
            listing_startdate: listing[0].listing_startdate,
            date_created: listing[0].date_created,
            listing_volunteer: listing[0].listing_volunteer,
            listing_frequency: listing[0].listing_frequency,
            listing_paytype: listing[0].listing_paytype,
            listing_payprice: listing[0].listing_payprice,
            listing_files: listing[0].listing_files,
            listing_url: listing[0].listing_url,
            listing_online: listing[0].listing_online,
            listing_kind: listing[0].listing_kind,
            listing_accomodation: listing[0].listing_accomodation,
            listing_availability: listing[0].listing_availability,
            listing_location: listing[0].listing_location
              ? listing[0].listing_location
              : "",
            listing_locationlat: listing[0].listing_locationlat
              ? listing[0].listing_locationlat
              : "",
            listing_locationlng: listing[0].listing_locationlng
              ? listing[0].listing_locationlng
              : "",
          },
          () => {
            document.getElementById("listing_location").value =
              this.state.listing_location;
          }
        );
      });
    }
  }

  handleChange = (address) => {
    this.setState({
      listing_location: "",
      listing_locationlat: "",
      listing_locationlng: "",
      address: address,
    });
  };

  handleSelect = (address) => {
    var vm = this;
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log("Success", latLng);
        vm.setState({
          listing_location: address,
          listing_locationlat: latLng.lat,
          listing_locationlng: latLng.lng,
          address: address,
        });
      })
      .catch(
        (error) =>
          function () {
            console.error("Error", error);
          }
      );
  };
  render() {
    Geocode.setApiKey("AIzaSyANFcGIwbJD5LnQfVNBlocD_T4vTs_fmpo");
    return (
      <Modal show={this.props.show} onHide={this.props.handleModal}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>
            <Modaltitle>
              {this.props.listid === 0 ? "Create Listing" : "Edit Listing"}
            </Modaltitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Subtitle>
            <BsFunnel size={20} color={"#92929D"} />
            <h3>Listing Type</h3>
          </Subtitle>
          <Subcontent>
            <div className="select-dropdown">
              <select
                required
                value={this.state.listing_type}
                onChange={(e) => this.selectListingType(e)}
              >
                <option value="" disabled hidden>
                  Select Listing Type
                </option>
                <option value="Earn2Burn Wanted">Earn2Burn Wanted</option>
                <option value="Learn2Burn Wanted">Learn2Burn Wanted</option>
                <option value="Training Services Wanted">
                  Training Services Wanted
                </option>
                <option value="Earn2Burn Available">Earn2Burn Available</option>
                <option value="Learn2Burn Available">
                  Learn2Burn Available
                </option>
                <option value="Training Services Available">
                  Training Services Available
                </option>
                <option value="Events">Events</option>
              </select>
              <span>
                <BsChevronDown size={12} />
              </span>
            </div>
          </Subcontent>
          {this.state.showListingTypeWarning && (
            <div
              role="alert"
              className="fade alert alert-danger show"
              style={alertStyle}
            >
              Please select listing type
            </div>
          )}
          <Subtitle>
            <BsChatDots
              size={20}
              color={"#92929D"}
              style={{ transform: "scale(-1, 1)" }}
            />
            <h3>Title</h3>
          </Subtitle>
          <Subcontent>
            <input
              id="listing_title"
              type="text"
              placeholder="Enter Title"
              defaultValue={this.state.listing_title}
              onKeyUp={(e) =>
                this.handleChildValueChanged({ listing_title: e.target.value })
              }
            />
          </Subcontent>
          {this.state.showListingTitleWarning && (
            <div
              role="alert"
              className="fade alert alert-danger show"
              style={alertStyle}
            >
              Please insert title
            </div>
          )}
          {(this.state.listing_type === "Earn2Burn Available" ||
            this.state.listing_type === "Learn2Burn Available") && (
            <div>
              <Subtitle>
                <BsChatDots
                  size={20}
                  color={"#92929D"}
                  style={{ transform: "scale(-1, 1)" }}
                />
                <h3>Business Name</h3>
              </Subtitle>
              <Subcontent>
                <input
                  id="business_name"
                  type="text"
                  placeholder="Enter Business Name"
                  defaultValue={this.state.business_name}
                  onKeyUp={(e) =>
                    this.handleChildValueChanged({
                      business_name: e.target.value,
                    })
                  }
                />
              </Subcontent>
            </div>
          )}
          {this.state.listing_type === "Earn2Burn Available" && (
            <div>
              <Subtitle>
                <BsChatDots
                  size={20}
                  color={"#92929D"}
                  style={{ transform: "scale(-1, 1)" }}
                />
                <h3>Business Info</h3>
              </Subtitle>
              <Subcontent>
                <input
                  id="business_info"
                  type="text"
                  placeholder="Enter Business Info"
                  defaultValue={this.state.business_info}
                  onKeyUp={(e) =>
                    this.handleChildValueChanged({
                      business_info: e.target.value,
                    })
                  }
                />
              </Subcontent>
            </div>
          )}
          <Subtitle>
            <BsJustifyLeft size={20} color={"#92929D"} />
            <h3>Description</h3>
          </Subtitle>
          <Subcontent>
            <textarea
              id="listing_description"
              rows="4"
              placeholder=""
              defaultValue={this.state.listing_description}
              onKeyUp={(e) =>
                this.handleChildValueChanged({
                  listing_description: e.target.value,
                })
              }
            ></textarea>
          </Subcontent>
          {this.state.showListingDesWarning && (
            <div
              role="alert"
              className="fade alert alert-danger show"
              style={alertStyle}
            >
              Please insert the description.
            </div>
          )}
          <Subtitle>
            <BsChatDots
              size={20}
              color={"#92929D"}
              style={{ transform: "scale(-1, 1)" }}
            />
            <h3>Location</h3>
          </Subtitle>
          <Subcontent>
            <input
              id="listing_location"
              placeholder="City/Town"
              className="location-search-input form-control"
              onChange={(e) =>
                this.handleChildValueChanged({
                  listing_location: e.target.value,
                  address: e.target.value,
                })
              }
            />
            {/* <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      id: "listing_location",
                      placeholder: "City/Town",
                      className: "location-search-input form-control",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={index}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete> */}
          </Subcontent>
          {this.state.showListingLocWarning && (
            <div
              role="alert"
              className="fade alert alert-danger show"
              style={alertStyle}
            >
              Please select a valid location.
            </div>
          )}
          {/* <Subtitle>
            <BsChatDots
              size={20}
              color={"#92929D"}
              style={{ transform: "scale(-1, 1)" }}
            />
            <h3>country</h3>
          </Subtitle>
          <Subcontent>
            <input
              id="listing_country"
              type="text"
              placeholder="country"
              onKeyUp={(e) =>
                this.handleChildValueChanged({
                  listing_country: e.target.value,
                })
              }
            />
          </Subcontent> */}
          {this.state.listing_type === "Events" && (
            <div>
              <Subtitle>
                <BsChatDots
                  size={20}
                  color={"#92929D"}
                  style={{ transform: "scale(-1, 1)" }}
                />
                <h3>Online Only</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`booleanOnlineBtn ${
                        this.state.listing_online === true ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanOnlineBtn(e, true)}
                    >
                      Yes
                    </Btn>
                    <Btn
                      className={`booleanOnlineBtn ${
                        this.state.listing_online === false ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanOnlineBtn(e, false)}
                    >
                      No
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {this.state.listing_type === "Events" && (
            <div>
              <Subtitle>
                <BsChatDots
                  size={20}
                  color={"#92929D"}
                  style={{ transform: "scale(-1, 1)" }}
                />
                <h3>Type</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`booleanKindBtn ${
                        this.state.listing_kind === "social" ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanKindBtn(e, "social")}
                    >
                      Social
                    </Btn>
                    <Btn
                      className={`booleanKindBtn ${
                        this.state.listing_kind === "learning" ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanKindBtn(e, "learning")}
                    >
                      Learning
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {this.state.listing_type === "Earn2Burn Wanted" && (
            <div>
              <Subtitle>
                <BsChatDots
                  size={20}
                  color={"#92929D"}
                  style={{ transform: "scale(-1, 1)" }}
                />
                <h3>Willing to relocate</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`booleanRelocateBtn ${
                        this.state.listing_relocate === true ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanRelocateBtn(e, true)}
                    >
                      Yes
                    </Btn>
                    <Btn
                      className={`booleanRelocateBtn ${
                        this.state.listing_relocate === false ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanRelocateBtn(e, false)}
                    >
                      No
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {(this.state.listing_type === "Earn2Burn Available" ||
            this.state.listing_type === "Earn2Burn Wanted") && (
            <div>
              <Subtitle>
                <BsFunnel size={20} color={"#92929D"} />
                <h3>Sector</h3>
              </Subtitle>
              <Subcontent>
                <div className="select-dropdown">
                  <select
                    required
                    value={this.props.listing_sector}
                    onChange={(e) =>
                      this.handleChildValueChanged({
                        listing_sector: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select a Sector
                    </option>
                    <option value="agricultural business">
                      Agricultural business
                    </option>
                    <option value="on farm">On farm</option>
                    <option value="forestry">Forestry</option>
                    <option value="veterinary">Veterinary</option>
                    <option value="agronomy">Agronomy</option>
                    <option value="horticultural">Horticultural</option>
                    <option value="house">House</option>
                    <option value="farm office">Farm office</option>
                    <option value="hgv">HGV</option>
                    <option value="general maintenance">
                      General maintenance
                    </option>
                    <option value="contracting">Contracting</option>
                    <option value="other">Other</option>
                    {this.state.listing_type === "Earn2Burn Wanted" && (
                      <option value="any">Any</option>
                    )}
                  </select>
                  <span>
                    <BsChevronDown size={12} />
                  </span>
                </div>
              </Subcontent>
            </div>
          )}
          {(this.state.listing_type === "Learn2Burn Available" ||
            this.state.listing_type === "Learn2Burn Wanted") && (
            <div>
              <Subtitle>
                <BsFunnel size={20} color={"#92929D"} />
                <h3>Sector</h3>
              </Subtitle>
              <Subcontent>
                <div className="select-dropdown">
                  <select
                    required
                    value={this.state.listing_sector}
                    onChange={(e) =>
                      this.handleChildValueChanged({
                        listing_sector: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select a Sector
                    </option>
                    <option value="agricultural">Agricultural</option>
                    <option value="house">House</option>
                    <option value="garden">Garden</option>
                    <option value="business">Business</option>
                    <option value="other">Other</option>
                    {this.state.listing_type === "Learn2Burn Wanted" && (
                      <option value="any">Any</option>
                    )}
                  </select>
                  <span>
                    <BsChevronDown size={12} />
                  </span>
                </div>
              </Subcontent>
            </div>
          )}
          {this.state.listing_sector === "on farm" && (
            <div>
              <Subtitle>
                <BsFunnel size={20} color={"#92929D"} />
                <h3>Categories</h3>
              </Subtitle>
              <Subcontent>
                <div className="select-dropdown">
                  <select
                    id="listing_category"
                    required
                    defaultValue={this.state.listing_category}
                    onChange={(e) =>
                      this.handleChildValueChanged({
                        listing_category: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled hidden>
                      Select a Category
                    </option>
                    <option value="livestock">livestock</option>
                    <option value="arable">arable</option>
                    <option value="fresh produce">fresh produce</option>
                    <option value="mixed">mixed</option>
                    <option value="poultry">poultry</option>
                    <option value="pigs">pigs</option>
                    <option value="dairy">dairy</option>
                    <option value="cattle">cattle</option>
                    <option value="sheep">sheep</option>
                    <option value="potatoes">potatoes</option>
                    <option value="other">other</option>
                  </select>
                  <span>
                    <BsChevronDown size={12} />
                  </span>
                </div>
              </Subcontent>
            </div>
          )}
          {(this.state.listing_type === "Earn2Burn Available" ||
            this.state.listing_type === "Learn2Burn Available") && (
            <div>
              <Subtitle>
                <BsHouseDoor size={20} color={"#92929D"} />
                <h3>Experience Needed</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`experienceBtn ${
                        this.state.listing_experienceneeded === true
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectExperienceBtn(e, true)}
                    >
                      Yes
                    </Btn>
                    <Btn
                      className={`experienceBtn ${
                        this.state.listing_experienceneeded === false
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectExperienceBtn(e, false)}
                    >
                      No
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {(this.state.listing_type === "Earn2Burn Available" ||
            this.state.listing_type === "Learn2Burn Available") && (
            <div>
              <Subtitle>
                <BsHouseDoor size={20} color={"#92929D"} />
                <h3>Accomodation Available</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`accomodationBtn ${
                        this.state.listing_accomodation === true ? "active" : ""
                      }`}
                      onClick={(e) => this.selectAccomodationBtn(e, true)}
                    >
                      Yes
                    </Btn>
                    <Btn
                      className={`accomodationBtn ${
                        this.state.listing_accomodation === false
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectAccomodationBtn(e, false)}
                    >
                      No
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {this.state.listing_type === "Earn2Burn Available" && (
            <div>
              <Subtitle>
                <BsHouseDoor size={20} color={"#92929D"} />
                <h3>Multiple Roles Available</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`booleanBtn ${
                        this.state.listing_mutiplerole === true ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanBtn(e, true)}
                    >
                      Yes
                    </Btn>
                    <Btn
                      className={`booleanBtn ${
                        this.state.listing_mutiplerole === false ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanBtn(e, false)}
                    >
                      No
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {(this.state.listing_type === "Earn2Burn Available" ||
            this.state.listing_type === "Earn2Burn Wanted" ||
            this.state.listing_type === "Learn2Burn Available" ||
            this.state.listing_type === "Training Services Wanted" ||
            this.state.listing_type === "Training Services Available" ||
            this.state.listing_type === "Events") && (
            <div>
              <Subtitle>
                <BsCalendar size={20} color={"#92929D"} />
                <h3>Start Date (optional)</h3>
              </Subtitle>
              <Subcontent>
                <input
                  id="listing_startdate"
                  type="date"
                  placeholder="DD/MM/YYYY"
                  defaultValue={this.state.listing_startdate}
                  onChange={(e) =>
                    this.handleChildValueChanged({
                      listing_startdate: e.target.value,
                    })
                  }
                />
              </Subcontent>
            </div>
          )}
          {this.state.listing_type === "Learn2Burn Available" && (
            <div>
              <Subtitle>
                <BsHouseDoor size={20} color={"#92929D"} />
                <h3>Volunteer Only</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`booleanVolunteerBtn ${
                        this.state.listing_volunteer === true ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanVolunteerBtn(e, true)}
                    >
                      Yes
                    </Btn>
                    <Btn
                      className={`booleanVolunteerBtn ${
                        this.state.listing_volunteer === false ? "active" : ""
                      }`}
                      onClick={(e) => this.selectbooleanVolunteerBtn(e, false)}
                    >
                      No
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {(this.state.listing_type === "Earn2Burn Available" ||
            this.state.listing_type === "Learn2Burn Available" ||
            this.state.listing_type === "Training Services Available" ||
            this.state.listing_type === "Events") && (
            <div>
              <Subtitle>
                <BsChatDots
                  size={20}
                  color={"#92929D"}
                  style={{ transform: "scale(-1, 1)" }}
                />
                <h3>URL (optional)</h3>
              </Subtitle>
              <Subcontent>
                <input
                  id="listing_url"
                  type="text"
                  placeholder="https://"
                  defaultValue={this.state.listing_url}
                  onKeyUp={(e) =>
                    this.handleChildValueChanged({
                      listing_url: e.target.value,
                    })
                  }
                />
              </Subcontent>
            </div>
          )}
          {this.state.listing_type === "Earn2Burn Available" && (
            <div>
              <Subtitle>
                <BsChatDots
                  size={20}
                  color={"#92929D"}
                  style={{ transform: "scale(-1, 1)" }}
                />
                <h3>Pay (optional)</h3>
              </Subtitle>
              <Subcontent>
                <input
                  id="listing_payprice"
                  type="text"
                  placeholder="£"
                  defaultValue={this.state.listing_payprice}
                  style={{ width: "20%", marginRight: "1%" }}
                  onKeyUp={(e) =>
                    this.handleChildValueChanged({
                      listing_payprice: e.target.value,
                    })
                  }
                />
                <div
                  className="select-dropdown"
                  style={{ width: "79%", float: "right" }}
                >
                  <select
                    id="listing_paytype"
                    required
                    defaultValue={this.state.listing_paytype}
                    onChange={(e) =>
                      this.handleChildValueChanged({
                        listing_paytype: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled hidden>
                      Hourly/ Monthy/ Yearly
                    </option>
                    <option value="hour">Hourly</option>
                    <option value="month">Monthly</option>
                    <option value="year">Yearly</option>
                  </select>
                  <span>
                    <BsChevronDown size={12} />
                  </span>
                </div>
              </Subcontent>
            </div>
          )}
          {this.state.listing_type === "Learn2Burn Wanted" && (
            <div>
              <Subtitle>
                <BsJustify size={20} color={"#92929D"} />
                <h3>Availability</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Monday(AM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Monday(AM)")
                      }
                    >
                      Monday(AM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Monday(PM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Monday(PM)")
                      }
                    >
                      Monday(PM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Tuesday(AM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Tuesday(AM)")
                      }
                    >
                      Tuesday(AM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Tuesday(PM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Tuesday(PM)")
                      }
                    >
                      Tuesday(PM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Wednesday(AM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Wednesday(AM)")
                      }
                    >
                      Wednesday(AM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Wednesday(PM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Wednesday(PM)")
                      }
                    >
                      Wednesday(PM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Thursday(AM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Thursday(AM)")
                      }
                    >
                      Thursday(AM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Thursday(PM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Thursday(PM)")
                      }
                    >
                      Thursday(PM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Friday(AM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Friday(AM)")
                      }
                    >
                      Friday(AM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Friday(PM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Friday(PM)")
                      }
                    >
                      Friday(PM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Friday(AM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Saturday(AM)")
                      }
                    >
                      Saturday(AM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Friday(PM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Saturday(PM)")
                      }
                    >
                      Saturday(PM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Friday(AM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Sunday(AM)")
                      }
                    >
                      Sunday(AM)
                    </Btn>
                    <Btn
                      className={`availabilityBtn ${
                        this.state.listing_availability.indexOf(
                          "Friday(PM)"
                        ) !== -1
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) =>
                        this.selectAvailabilityBtn(e, "Sunday(PM)")
                      }
                    >
                      Sunday(PM)
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {(this.state.listing_type === "Earn2Burn Available" ||
            this.state.listing_type === "Earn2Burn Wanted" ||
            this.state.listing_type === "Learn2Burn Available") && (
            <div>
              <Subtitle>
                <BsJustify size={20} color={"#92929D"} />
                <h3>Frequency</h3>
              </Subtitle>
              <Subcontent>
                <Row>
                  <Col>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "casual"
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "casual")}
                    >
                      casual
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "relief"
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "relief")}
                    >
                      relief
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "contract"
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "contract")}
                    >
                      contract
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "full time"
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "full time")}
                    >
                      full time
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "part time"
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "part time")}
                    >
                      part time
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "seasonal"
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "seasonal")}
                    >
                      seasonal
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "one off"
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "one off")}
                    >
                      one off
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "flexible"
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "flexible")}
                    >
                      flexible
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "any" ? "active" : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "any")}
                    >
                      any
                    </Btn>
                    <Btn
                      className={`frequencyBtn ${
                        this.state.listing_frequency === "other" ? "active" : ""
                      }`}
                      onClick={(e) => this.selectFrequencyBtn(e, "other")}
                    >
                      other
                    </Btn>
                  </Col>
                </Row>
              </Subcontent>
            </div>
          )}
          {(this.state.listing_type === "Earn2Burn Available" ||
            this.state.listing_type === "Learn2Burn Available" ||
            this.state.listing_type === "Events") && (
            <div>
              <Subtitle>
                <BsPaperclip
                  size={20}
                  color={"#92929D"}
                  style={{ transform: "rotate(60deg)" }}
                />
                <h3>Attach Images/ Documents (Optional)</h3>
              </Subtitle>
              <Subcontent>
                <div className="uploadfilediv mt-3">
                  <div className="clickdoc">
                    <BsFillPlusCircleFill size={16} className="mr-2" />
                    Upload Documents
                  </div>
                  <input
                    type="file"
                    className="uploadfile"
                    onChange={(event) => this.uploadfile(event)}
                  />
                  <Uploadimg
                    className={`${
                      this.state.listing_files.length > 0 ? "" : "d-none"
                    }`}
                  >
                    {this.state.listing_files &&
                      Object.values(this.state.listing_files).map(
                        (file, index) => (
                          <Uploaded key={index}>
                            <img
                              alt="uploadedimg"
                              className="mr-1 imgurl"
                              src={file}
                            />
                            <BsX
                              size={30}
                              color={"#92929D"}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "10px",
                              }}
                              onClick={() => this.remove(index)}
                            />
                          </Uploaded>
                        )
                      )}
                  </Uploadimg>
                </div>
              </Subcontent>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button onClick={this.handleSave}>
            {this.props.listid === 0 ? "Create Listing" : "Save changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Listmodal);
