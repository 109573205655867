import React from "react";
import { withRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Header from "./layout/Header";

import { createUserProfileDocument } from "./utils/firebase/firestore";
import { auth } from "./utils/firebase/auth";
import { connect } from "react-redux";
import { setCurrentUser } from "./utils/redux/user/user.action";
import history from "./history";
import ListingModal from "./components/ListingModal";

import "./App.scss";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      modalshow: false,
    };
  }
  unsubscribeFromAuth = null;

  componentDidMount() {
    const { setCurrentUser } = this.props;

    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth && userAuth.emailVerified) {
        const userRef = await createUserProfileDocument(userAuth);
        // When snapshot is updated
        userRef.onSnapshot((snapshot) => {
          setCurrentUser({
            id: snapshot.id,
            ...snapshot.data(),
          });
        });
      } else {
        setCurrentUser(null);
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }
  handleModal = () => {
    this.setState({ modalshow: !this.state.modalshow });
  };

  render() {
    return (
      <div className="container-scroller" style={{ background: "#fff" }}>
        <Header
          currentUser={this.props.currentUser}
          handleModal={this.handleModal}
        />
        <AppRoutes currentUser={this.props.currentUser} history={history} />
        <ListingModal
          show={this.state.modalshow}
          handleModal={this.handleModal}
          profileimg={""}
          listid={0}
        />
      </div>
    );
  }
}

// export default withRouter(App);

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

const mapDispatchStateToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

withRouter(App);

export default connect(mapStateToProps, mapDispatchStateToProps)(App);
