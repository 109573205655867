import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "./components/Spinner";

const Signup = lazy(() => import("./pages/Signup"));
const Signin = lazy(() => import("./pages/Signin"));
const Account = lazy(() => import("./pages/Account"));
const GeneralSetting = lazy(() => import("./pages/Generalsetting"));
const Resource = lazy(() => import("./pages/Resource"));
const Profile = lazy(() => import("./pages/Profile"));
const BusinessProfile = lazy(() => import("./pages/BusinessProfile"));
const Mydocument = lazy(() => import("./pages/Mydoc"));
const Mylisting = lazy(() => import("./pages/Mylisting"));
const Listing = lazy(() => import("./pages/Listing"));
const Myjob = lazy(() => import("./pages/Myjob"));
const Message = lazy(() => import("./pages/Message"));
const ViewProfile = lazy(() => import("./pages/ViewProfile"));
const Error404 = lazy(() => import("./pages/Error404"));

function AppRoutes(props) {
  console.log(props)
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* <Route exact path="/signup" component={Signup} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/account" component={Account} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/business-profile" component={BusinessProfile} /> */}

        <Route
          exact
          path="/signup"
          render={() =>
            props.currentUser ? <Redirect to="/account" /> : <Signup />
          }
        />

        <Route
          exact
          path="/signup-f"
          render={() =>
            props.currentUser ? <Redirect to="/account" /> : <Signin />
          }
        />

        <Route
          exact
          path="/signin"
          render={() =>
            props.currentUser ? <Redirect to="/profile" /> : <Signin />
          }
        />

        <Route
          exact
          path="/account"
          render={() =>
            props.currentUser ? <Account /> : <Redirect to="/signup" />
          }
        />
        <Route
          exact
          path="/generalsetting"
          render={() =>
            props.currentUser ? <GeneralSetting /> : <Redirect to="/signup" />
          }
        />

        <Route
          exact
          path="/profile"
          render={() =>
            props.currentUser?.username ? <Profile /> : <Redirect to="/account" />
          }
        />
        <Route
          exact
          path="/business-profile"
          render={() =>
            props.currentUser?.username  ? <BusinessProfile /> : <Redirect to="/account" />
          }
        />
        <Route
          exact
          path="/mydocument"
          render={() =>
            props.currentUser?.username  ? <Mydocument /> : <Redirect to="/account" />
          }
        />
        <Route
          exact
          path="/mylisting"
          render={() =>
            props.currentUser?.username  ? <Mylisting /> : <Redirect to="/account" />
          }
        />
        <Route
          exact
          path="/myjob"
          render={() =>
            props.currentUser?.username  ? <Myjob /> : <Redirect to="/account" />
          }
        />
        <Route
          exact
          path="/resource"
          render={() =>
            props.currentUser?.username  ? <Resource /> : <Redirect to="/account" />
          }
        />
        <Route
          exact
          path="/message"
          render={() =>
            props.currentUser?.username  ? <Message /> : <Redirect to="/account" />
          }
        />
        <Route
          exact
          path="/viewprofile/:id"
          render={() =>
            props.currentUser?.username  ? <ViewProfile /> : <Redirect to="/account" />
          }
        />
        <Route
          exact
          path="/listings/:id"
          render={() =>
            props.currentUser?.username  ? <Listing /> : <Redirect to="/account" />
          }
        />
        {/* Page redirection */}
        <Redirect exact from="/" to="/profile" />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
}

export default AppRoutes;
